<template>
  <div class="container-fluid">

    <div class="row no-gutters">
      <!-- Buffer div -->
      <div class="col-1"></div>

      <!-- Main content div -->
      <div class="col-10">

        <h4>Coming soon, pool info</h4>

      </div>

      <!-- Buffer div -->
      <div class="col-1"></div>

    </div>
  </div>
</template>


<script>


export default {
  name: 'PoolParties',
  components: {

  },
  mixins: [],
  data() {
    return {
      searchValue: '',
    }
  },
  mounted() {

  },
  beforeUnmount() {

  },
  methods: {

  }
}
</script>

<style>

h4 {
  margin-top: 20px;
  margin-bottom: 20px;
}

</style>